// Notes about testing: https://github.com/visgl/react-map-gl/issues/1478
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC, useRef, useState } from 'react';
import ReactMapGl, {
  Source,
  Layer,
  MapboxGeoJSONFeature,
  MapRef,
  NavigationControl,
  MapLayerMouseEvent,
  Marker,
} from 'react-map-gl';
import { AllGeoJSON } from '@turf/helpers';
import 'mapbox-gl/dist/mapbox-gl.css';
import turf from '@turf/centroid';
import { useRepository } from '@context/repository.context';
import { useQuery } from 'react-query';
import { MapMouseEvent } from 'mapbox-gl';
import { ICountryProperties, IDropdownOption, IFacility, IFeature, Nullable } from '@common/interfaces';
import { Button, Dropdown, Main } from '@components';
import { ArrowIcon } from '@assets/icons';
import { ArrowRightIcon, CloseIcon, WrenchIcon } from '@assets/svg';
import css from './styles.module.scss';

const MAPBOX_API_KEY = process.env.REACT_APP_MAPBOX_API_KEY;
const mapboxStyle = 'mapbox://styles/ie3yit/cjok5bbmd0ach2spkmxoeaziz';

interface IExceptionalBoundaries {
  [key: string]: [number, number];
}

const exceptionalBoundaries: IExceptionalBoundaries = {
  USA: [-85.299591, 35.116386],
};

function find<T>(array: T[], predicate: (value: T, index: number, obj: T[]) => boolean): T | undefined {
  return array.find(predicate);
}

const enableMapboxHandlers = (mapRef: MapRef) => {
  const map = mapRef.getMap();
  map.doubleClickZoom.enable();
  map.keyboard.enable();

  map.flyTo({ zoom: 2.5 });
  map.removeFeatureState({ source: 'grouped-countries' });
};

const disableMapboxHandlers = (mapRef: MapRef) => {
  const map = mapRef.getMap();
  map.doubleClickZoom.disable();
  map.keyboard.disable();
};

const unpackCoordinates = (coordinates: any): number[][] => {
  if (!coordinates) {
    return [];
  }
  const coords = [];
  for (const coord of coordinates) {
    if (!Array.isArray(coord[0])) {
      coords.push(coord);
    } else {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      coords.push(...unpackCoordinates(coord));
    }
  }
  return coords;
};

const onClickHandler = (
  event: MapLayerMouseEvent,
  mapRef: MapRef,
  setSelectedCountry: React.Dispatch<React.SetStateAction<ICountryProperties | null>>
) => {
  if (event.features) {
    const feature = event.features[0];

    // calculateBounds

    if (!feature?.geometry) return false;
    const centroid = turf(feature?.geometry as AllGeoJSON).geometry.coordinates;

    mapRef.getMap().flyTo({
      zoom: 4,
      speed: 0.45,
      center: exceptionalBoundaries[feature?.properties?.id] || centroid,
      pitch: 25,
      padding: { right: 150, left: 0, top: 0, bottom: 0 },
    });

    disableMapboxHandlers(mapRef);
    setSelectedCountry(feature?.properties as ICountryProperties);
  }
};

const onMouseHandler = (
  event: MapLayerMouseEvent,
  selectedCountry: ICountryProperties | null,
  hoveredCountry: number | null,
  setHoveredCountry: React.Dispatch<React.SetStateAction<number | null>>
) => {
  if (event.features) {
    const featureId = event.features[0]?.id;

    if (!featureId || selectedCountry) {
      return (event.target.getCanvas().style.cursor = 'default');
    }

    if (hoveredCountry && featureId !== hoveredCountry) {
      event.target.removeFeatureState({ source: 'grouped-countries', id: hoveredCountry }, 'select');
    }

    setHoveredCountry(featureId as number);
    event.target.getCanvas().style.cursor = 'pointer';
    event.target.setFeatureState({ source: 'grouped-countries', id: featureId }, { select: true });
  }
};

const highlightedCountriesBorderLayer = {
  id: 'geojson-layer-borders',
  type: 'line' as 'sky',
  beforeId: 'country-label',
  source: 'grouped-countries',
};

const highlightedCountriesLayer = {
  id: 'grouped-geojson-layer-fill',
  type: 'fill' as 'sky',
  beforeId: 'country-label',
  paint: {
    'fill-color': ['case', ['boolean', ['feature-state', 'select'], false], '#5cc3e6', '#135D7E'],
  },
};

const BookMaintenancePage: FC = () => {
  const [hoveredCountry, setHoveredCountry] = useState<Nullable<number>>(null);
  const [features, setFeatures] = useState<IFeature[]>([]);
  const [viewport, setViewport] = useState({
    zoom: 2.6,
    longitude: 20.68674593817775,
    latitude: 7.7439240888921,
  });

  const [isMenuHidden, setIsMenuHidden] = useState(false);
  const [countryDropdownValue, setCountryDropdownValue] = useState<Nullable<IDropdownOption>>(null);

  const [selectedCountry, setSelectedCountry] = useState<Nullable<ICountryProperties>>(null);
  const [selectedWorkshop, setSelectedWorkshop] = useState<Nullable<IFacility>>(null);

  const mapRef = useRef(null);

  const { facilityRepository } = useRepository();

  const { data: geoJson, isLoading: isGeoLoading } = useQuery(`geo-json`, () => facilityRepository.getGeoJSON(), {
    onSuccess: ({ features }: { features: IFeature[] }) => {
      const convertedFeatures = features.map((feature, key) => ({
        ...feature,
        id: key,
      }));

      setFeatures(convertedFeatures as any);
    },
  });

  const { data: facilities, isLoading } = useQuery<IFacility[]>(`facilities`, () => facilityRepository.getFacilities());

  const filledFacilities = facilities
    ? facilities.filter((item) => {
        return item.country_name !== '';
      })
    : [];

  const uniqueCountries = filledFacilities
    .filter((item, index, self) => index === self.findIndex((t) => t.country_name === item.country_name))
    .map((country) => {
      const feature = find<IFeature>(
        geoJson?.features || [],
        (feature) => feature?.properties?.name === country.country_name
      );
      return { label: feature?.properties?.name || '', value: feature?.properties?.name || '' };
    });

  const filteredCountries = {
    type: 'FeatureCollection',
    features: features
      ? features.filter((feature: IFeature) => uniqueCountries.find(({ value }) => value === feature?.properties?.name))
      : [],
  };

  const selectedCountryWorkshops =
    selectedCountry && facilities ? facilities.filter(({ country_name }) => country_name === selectedCountry.name) : [];

  const onMouseLeaveHandler = (event: MapMouseEvent) => {
    hoveredCountry && event.target.removeFeatureState({ source: 'grouped-countries', id: hoveredCountry }, 'select');
    setHoveredCountry(null);
  };

  const onCountryDropdownClick = () => {
    if (!countryDropdownValue) return false;

    const map = (mapRef?.current as unknown as MapRef)?.getMap();
    const feature = find(
      filteredCountries.features,
      (mbFeature) => mbFeature.properties.name === countryDropdownValue?.value
    );

    if (!feature?.geometry) return false;
    const centroid = turf(feature.geometry).geometry.coordinates;

    map.setFeatureState({ source: 'grouped-countries', id: feature?.id }, { select: true });

    map.flyTo({
      zoom: 4,
      speed: 0.45,
      center: exceptionalBoundaries[feature.properties.id] || centroid,
      pitch: 25,
    });

    disableMapboxHandlers(mapRef.current as unknown as MapRef);

    setSelectedCountry(feature?.properties);
    setSelectedWorkshop(null);
  };

  const onMapClickHandler = (e: MapLayerMouseEvent) => {
    if (!selectedCountry) {
      onClickHandler(e, mapRef.current as unknown as MapRef, setSelectedCountry);
    } else {
      setSelectedCountry(null);
      enableMapboxHandlers(mapRef.current as unknown as MapRef);
    }
  };

  return (
    <Main background='none' loading={isLoading || isGeoLoading} withoutPaddings>
      <div className={css.container}>
        <div className={`${css.info} ${isMenuHidden ? css.hidden : ''}`}>
          <div className={`${css.buttonSwitch} ${css.buttonHide}`} onClick={() => setIsMenuHidden(true)}>
            <ArrowIcon type='left' />
            Hide
          </div>
          <div className={`${css.buttonSwitch} ${css.buttonShow}`} onClick={() => setIsMenuHidden(false)}>
            Show
            <ArrowIcon type='right' />
          </div>
          <div className={css.title}>Book maintenance services</div>
          <div className={css.description}>
            Our workshops professional technicians, mechanics, and advanced equipment cater to all maintenance needs,
            from damage repairs to routine check-ups, for your car, truck or other assets. We always use genuine spare
            parts in our workshops to minimize defects and frequent repairs.
          </div>
          <div className={css.counters}>
            <div className={css.counter}>
              <div className={css.label}>Countries with active workshops</div>
              <div className={css.value}>{uniqueCountries.length}</div>
            </div>
            <div className={css.counter}>
              <div className={css.label}>Workshops</div>
              <div className={css.value}>{filledFacilities.length}</div>
            </div>
          </div>
          <div className={css.control}>
            <Dropdown
              placeholder='Select country'
              className={css.dropdown}
              onChange={setCountryDropdownValue}
              options={uniqueCountries}
              menuPlacement='top'
            />
            <Button onClick={onCountryDropdownClick} text='Go to country' />
          </div>
        </div>

        <div className={`${css.popup} ${css.popupCountry} ${selectedCountry ? css.active : ''}`}>
          <div className={css.title}>
            {selectedCountry?.name}
            <Button
              variant='icon'
              iconL={<CloseIcon />}
              onClick={() => {
                setSelectedCountry(null);
                enableMapboxHandlers(mapRef.current as unknown as MapRef);
              }}
            />
          </div>
          <div className={css.countryCover}></div>
          <div className={css.content}>
            <div className={css.subtitle}>Select a workshop:</div>
            {selectedCountryWorkshops.map((workshop, idx) => {
              const { city_name, name, agency } = workshop;
              return (
                <div key={idx} className={css.item} onClick={() => setSelectedWorkshop(workshop)}>
                  <WrenchIcon />
                  {`${city_name} ${name} - ${agency}`}
                </div>
              );
            })}
          </div>
        </div>

        <div className={`${css.popup} ${css.popupWorkshop} ${selectedWorkshop ? css.active : ''}`}>
          <div className={css.title}>
            <div className={css.text}>{`${selectedWorkshop?.city_name} - ${selectedWorkshop?.name}`}</div>
            <Button variant='icon' iconR={<CloseIcon />} onClick={() => setSelectedWorkshop(null)} />
          </div>
          {selectedWorkshop?.image ? (
            <div className={css.cover}>
              <img src={selectedWorkshop.image} alt='' />
            </div>
          ) : (
            <div className={css.workshopCover}></div>
          )}
          <Button
            text='Request maintenance'
            link={`/book-maintenance/${selectedWorkshop?.facility_id_on_hbh}/`}
            iconR={<ArrowRightIcon />}
            className={css.button}
          />
          <div className={css.content}>
            <div className={css.subtitle}>Services offered:</div>
            {selectedWorkshop?.services.map(({ type_of_asset }, idx) => {
              return (
                <div key={idx} className={css.item}>
                  <WrenchIcon />
                  {type_of_asset}
                </div>
              );
            })}
            {selectedWorkshop?.services.length === 0 ? 'No services' : null}
          </div>
        </div>

        <ReactMapGl
          attributionControl={false}
          initialViewState={viewport}
          mapStyle={mapboxStyle}
          minZoom={2}
          maxZoom={6}
          mapboxAccessToken={MAPBOX_API_KEY}
          doubleClickZoom={false}
          onMouseMove={(e) => onMouseHandler(e, selectedCountry, hoveredCountry, setHoveredCountry)}
          onMouseLeave={(event) => !selectedCountry && onMouseLeaveHandler(event)}
          onClick={onMapClickHandler}
          interactiveLayerIds={['geojson-layer-borders', 'grouped-geojson-layer-fill']}
          onMove={(evt) => {
            setViewport(evt.viewState);
          }}
          ref={mapRef}
        >
          <NavigationControl />
          <Source id='grouped-countries' type='geojson' data={filteredCountries as unknown as MapboxGeoJSONFeature}>
            <Layer {...(highlightedCountriesLayer as any)} />
            <Layer {...highlightedCountriesBorderLayer} />
          </Source>
          {selectedCountryWorkshops.map((workshop) => {
            const { uuid, city_name, longitude, latitude } = workshop;

            // Note: we should avoid these cases on BE side
            if (longitude > 180 || longitude < -180 || latitude > 180 || latitude < -180) return null;

            return (
              <Marker
                key={uuid}
                onClick={(e) => {
                  e.originalEvent.stopPropagation();
                  setSelectedWorkshop(workshop);
                }}
                longitude={longitude}
                latitude={latitude}
                anchor='left'
              >
                <div className={css.city}>
                  <WrenchIcon /> {city_name}
                </div>
              </Marker>
            );
          })}
        </ReactMapGl>
      </div>
    </Main>
  );
};

export default BookMaintenancePage;
